// import 'babel-polyfill'
import Util from './util'
import Api from './api'
// import Rails from 'rails-ujs'

const util = new Util()
const api = new Api()

class Sessions {
  constructor () {
    util.checkUA()
    util.setParams()
  }

  main() {
    const acceptBtn = document.getElementById('term-accept')

    // ポップアップ切り替え
    util.setTab('data-tab', 'data-tabtgt')
    if (acceptBtn) {
      acceptBtn.addEventListener('click', function() {
        document.body.classList.add('accepted')
      }, false)
    }

    // スクロール判定
    const scrollTgt = document.getElementById('js-termscrollBox')
    if (scrollTgt) {
      const btntgt = document.getElementById('term-accept')
      scrollTgt.addEventListener('scroll', function() {
        const max = scrollTgt.scrollHeight - scrollTgt.offsetHeight;
        if (Math.round( scrollTgt.scrollTop / max * 100 ) === 100) {
          btntgt.classList.remove('common-btn-passive')
          btntgt.classList.add('common-btn-active')
        }
      }, false)
    }

    // ログインボタン活性化
    /*
    const sessionname = document.getElementById('session_name')
    const sessionpw = document.getElementById('session_password')
    const loginbtn = document.querySelector('.loginbtn')
    if (sessionname && sessionpw && loginbtn) {
      const checkVal = function() {
        if (sessionname.value.length > 0 && sessionpw.value.length > 0) {
          loginbtn.classList.remove('noactive')
        } else {
          loginbtn.classList.add('noactive')
        }
      }
      sessionname.addEventListener('keyup', checkVal, false);
      sessionpw.addEventListener('keyup', checkVal, false)
      setTimeout(function() {
        console.log(sessionname.value.length)
        console.log(sessionpw.value.length)
        checkVal()
      }, 2000)
    }
    */
  }

  init () {
    this.main()
  }
}

const sessions = new Sessions()

document.addEventListener("DOMContentLoaded", () => {
  sessions.init()
})

// Rails.start()
