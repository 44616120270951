/**
 * 共通で利用するメソッド
 */
export default class Util {
  /**
   * UA判定
   */
  checkUA () {
    const userAgent = navigator.userAgent.toLowerCase()
    const appVersion = navigator.appVersion.toLowerCase()
    // デバイス判定
    if (userAgent.indexOf('iphone') > 0 || userAgent.indexOf('ipod') > 0 || userAgent.indexOf('android') > 0) {
      this.uaDevice = 'device-mobile'
    }
    // ブラウザ判定
    if (userAgent.indexOf('opera') !== -1) {
      this.uaBrouser = 'opera'
    } else if (userAgent.indexOf('msie') !== -1) {
      if (appVersion.indexOf('msie 6.') !== -1) {
        this.uaBrouser = 'ie6'
      } else if (appVersion.indexOf('msie 7.') !== -1) {
        this.uaBrouser = 'ie7'
      } else if (appVersion.indexOf('msie 8.') !== -1) {
        this.uaBrouser = 'ie8'
      } else if (appVersion.indexOf('msie 9.') !== -1) {
        this.uaBrouser = 'ie9'
      } else {
        this.uaBrouser = 'ie'
      }
    } else if (userAgent.indexOf('trident') !== -1) {
      this.uaBrouser = 'ie11'
    } else if (userAgent.indexOf('chrome') !== -1) {
      this.uaBrouser = 'chrome'
    } else if (userAgent.indexOf('safari') !== -1) {
      this.uaBrouser = 'safari'
    } else if (userAgent.indexOf('firefox') !== -1) {
      this.uaBrouser = 'firefox'
    } else {
      this.uaBrouser = false
    }

    this.eventType = (this.uaDevice === 'device-mobile') ? 'touchend' : 'click'
  }

  /**
   URLパラメータをオブジェクト化して保持
   */
  setParams () {
    if (location.search) {
      this.params = window.location.search.substring(1).split('&').reduce((result, query) => { let pair = query.split('='); result[pair[0]] = decodeURI(pair[1]); return result }, {})
    } else {
      this.params = {}
    }
  }

  /**
   URLパラメータをオブジェクト化したものを返す
   */
  getParams () {
    return this.params
  }

  /**
   複数セレクタから指定のclassを削除
   * @param {obj} tgts classを削除したいオブジェクト
   * @param {string} cname 削除するclass名
   */
  removeClasses (tgts, cname) {
    for (let i = 0; i < tgts.length; i++) {
      tgts[i].classList.remove(cname)
    }
  }

  /**
   複数セレクタに同じイベントを追加 引き数には自身が入る
   * @param {string} handler イベントハンドラ
   * @param {obj} tgts イベントを追加したいオブジェクト
   * @param {obj} cb 実行するメソッド名
   */
  setEventsRtSelf (handler, tgts, cb) {
    for (let i = 0; i < tgts.length; i++) {
      tgts[i].addEventListener(handler, () => {
        cb(tgts[i])
      }, false)
    }
  }

  /**
   単一セレクタに同じイベントを追加 引き数には自身が入る
   * @param {string} handler イベントハンドラ
   * @param {obj} tgt イベントを追加したいオブジェクト
   * @param {obj} cb 実行するメソッド名
   */
  setEventsRtSelfSingle (handler, tgt, cb) {
    tgt.addEventListener(handler, () => {
      cb(tgt)
    }, false)
  }

  /**
   タブ切り替え処理
   * @param {string} btnname クリックイベントを追加するタブ切り替えボタンのdata属性名
   * @param {string} tgtname タブ切り替えのターゲットとなる要素のdata属性名
   */
  setTab (btnname, tgtname) {
    const tabbtns = document.querySelectorAll('[' + btnname + ']')

    /**
     タブ切り替え処理
     * @param {string} self クリックされたボタン自身
     */
    const toggleTab = (self) => {
      const num = self.getAttribute(btnname);
      const tgtcontent = document.querySelector('[' + tgtname +'="' + num + '"]')

      if (tgtcontent) {
        this.removeClasses(tabbtns, 'active')
        self.classList.add('active')

        this.removeClasses(document.querySelectorAll('[' + tgtname + ']'), 'active')
        tgtcontent.classList.add('active')
      }
    }

    if (tabbtns) {
      this.setEventsRtSelf('click', tabbtns, toggleTab)
    }
  }

  /**
   トグル処理
   * @param {string} name クリックイベントを追加するタブ切り替えボタンのdata属性名
   * @param {string} tgtname タブ切り替えのターゲットとなる要素のdata属性名
   */
  setToggle (btnname, tgtname) {
    const tabbtns = document.querySelectorAll('[' + btnname + ']')

    /**
     タブ切り替え処理
     * @param {string} self クリックされたボタン自身
     */
    const toggleTab = (self) => {
      const num = self.getAttribute(btnname);
      const tgtcontent = document.querySelector('[' + tgtname +'="' + num + '"]')

      if (tgtcontent) {
        if (self.classList.contains('active')) {
          self.classList.remove('active')
          tgtcontent.classList.remove('active')
        } else {
          self.classList.add('active')
          tgtcontent.classList.add('active')
        }
      }
    }

    if (tabbtns) {
      this.setEventsRtSelf('click', tabbtns, toggleTab)
    }
  }

  /**
   確認文字出力
   */
  showConfirmTxt () {
    const tgts = document.querySelectorAll('[data-confirmsync]')

    const cbInput = (self, tgt) => {
      tgt.innerText = self.value
    }

    if (tgts) {
      for (let i = 0; i < tgts.length; i++) {
        const tgtname = tgts[i].getAttribute('data-confirmsync')
        const tgtinput = document.querySelector('[name="' + tgtname + '"]')
        if (tgtinput) {
          tgtinput.addEventListener('keyup', () => {
            cbInput(tgtinput, tgts[i])
          }, false)
          cbInput(tgtinput, tgts[i])
        }
      }
    }
  }
}
