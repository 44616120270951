/**
 * APIを叩く系
 */
import axios from 'axios'

export default class Api {
  constructor () {
    this._reqParams = {} // リクエストパラメータ用オブジェクト
    this._apidata = {}
  }

  /**
   * WF 各種API情報取得
   * @param {string} apiname API名
   * @return {object} 各APIのプロパティ
   */
  getApiData (apiname) {
    return this._apidata[apiname]
  }

  /**
   * WF APIの共通プロパティ取得
   * @return {object} 共通のパラメータ
   */
  getCommonProp () {
    return {
      baseURL: '/',
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'json'
    }
  }

  /**
   * リクエストパラメータオブジェクト取得
   * @param {string} apiname API名
   * @return {obj} APIごとのリクエストパラメータをオブジェクト形式で返す
   */
  getRparamsObj (apiname) {
    return this._reqParams[apiname]
  }

  /**
   * リクエストパラメータのセッター
   * @param {string} apiname API名
   * @param {string} key キー
   * @param {obj} val 値
   */
  setRparamsObj (apiname, key, val) {
    if (!this._reqParams[apiname]) {
      this._reqParams[apiname] = {}
    }

    this._reqParams[apiname][key] = val
  }

  /**
   * リクエストパラメータのプロパティ削除（ブランクが不可の場合に利用）
   * @param {string} apiname API名
   * @param {string} key キー
   * @param {obj} val 値
   */
  deleteRparamsObj (apiname, key) {
    if (this._reqParams[apiname] && this._reqParams[apiname][key]) {
      delete this._reqParams[apiname][key]
    }
  }

  /**
   * APIを叩く共通動作
   * @param {string} apiname API名
   * @param {function} callback コールバックメソッド
   * @return {boolean} 正しくAPIが叩けたかどうか
   */
  manageApi (apiname, callback) {
    const apiOpt = this.getApiData(apiname)
    const commonProp = this.getCommonProp()
    const axIns = axios.create(commonProp)

    const opt = {
      method: apiOpt.method,
      url: apiOpt.url
    }

    let stateP = this.getRparamsObj(apiname)

    if (apiOpt.method === 'get') {
      opt.params = stateP
    } else {
      opt.data = stateP
    }

    axIns(opt).then((response) => {
      if (response && response.status === 200) {
        if (location.host === 'localhost:3000' || location.host === 'localhost:3008' || location.host.match("dev")) {
          console.log(response.data)
        }
        callback(response.data)
        return true
      } else {
        return false
      }
    }).catch((e) => {
      return false
    })
  }
}
